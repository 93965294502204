// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en

//@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@import "../node_modules/ng-zorro-antd/style/default.less";
@import "../node_modules/ng-zorro-antd/style/patch.less";

// used components
@import "../node_modules/ng-zorro-antd/collapse/style/entry.less";
@import "../node_modules/ng-zorro-antd/dropdown/style/entry.less";
@import "../node_modules/ng-zorro-antd/modal/style/entry.less";
@import "../node_modules/ng-zorro-antd/divider/style/entry.less";
@import "../node_modules/ng-zorro-antd/rate/style/entry.less";
@import "../node_modules/ng-zorro-antd/drawer/style/entry.less";
@import "../node_modules/ng-zorro-antd/skeleton/style/entry.less";
@import "../node_modules/ng-zorro-antd/radio/style/entry.less";
@import "../node_modules/ng-zorro-antd/switch/style/entry.less";
@import "../node_modules/ng-zorro-antd/slider/style/entry.less";
@import "../node_modules/ng-zorro-antd/segmented/style/entry.less";
@import "../node_modules/ng-zorro-antd/popover/style/entry.less";
@import "../node_modules/ng-zorro-antd/tooltip/style/entry.less";
@import "../node_modules/ng-zorro-antd/message/style/entry.less";
@import "../node_modules/ng-zorro-antd/notification/style/entry.less";
@import "../node_modules/ng-zorro-antd/date-picker/style/entry.less";
@import "../node_modules/ng-zorro-antd/auto-complete/style/entry.less";
@import "../node_modules/ng-zorro-antd/table/style/entry.less";
@import "../node_modules/ng-zorro-antd/spin/style/entry.less";

@import "../node_modules/ng-zorro-antd/resizable/style/entry.less";
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #4B57DC;
@text-selection-bg: #6671e5;
@font-family: 'Poppins', sans-serif;
